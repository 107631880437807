import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { GridSpacing } from '@mui/material';
import { CSSObject, ResponsiveStyleValue } from '@mui/system';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '@ui/slice-machine/slices/constants';
import { useMemo } from 'react';

export interface UseTenantComponentStylesReturn {
  spacing: ResponsiveStyleValue<GridSpacing>;
  borderRadius: ResponsiveStyleValue<CSSObject['borderRadius']>;
}

export const useTenantComponentStyles = (): UseTenantComponentStylesReturn => {
  const { tenant } = useTenantProps();
  const spacing = useMemo(
    () =>
      tenant?.theme?.componentStyles?.spacing ??
      DEFAULT_SPACING_BETWEEN_ELEMENTS,
    [tenant],
  );
  const borderRadius = useMemo(
    () => tenant?.theme?.componentStyles?.borderRadius ?? 2,
    [tenant],
  );

  return { spacing, borderRadius };
};
