import { FC, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

interface Props {
  icon: ReactNode;
  title: string;
}

export const GenericPlaceholder: FC<Props> = (props) => {
  const { icon, title } = props;

  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {icon}
      <Typography variant="h6">{title}</Typography>
    </Stack>
  );
};
