import { FC } from 'react';

import { BannerCard } from './banner-with-card/BannerCard';
import { DefaultBanner } from './default-slice/DefaultBanner';
import {
  BannerCardVariation,
  BannerCardWithImagesVariation,
  BannerVariations,
  BannerWithBorderedImageVariation,
  BannerWithColumnsVariation,
  BannerWithCountrySearchVariation,
  BannerWithPageFilterVariation,
  BannerWithVideoVariation,
  DefaultBannerVariation,
} from './types';
import { BannerCardWithImages } from './banner-card-with-images/BannerCardWithImages';
import { BannerWithBorderedImage } from './banner-with-bordered-image/BannerWithBorderedImage';
import { BannerWithColumns } from './banner-with-columns/BannerWithColumns';
import { BannerWithVideo } from './banner-with-video/BannerWithVideo';
import { BannerWithCountrySearch } from './banner-with-country-search/BannerWithCountrySearch';
import { BannerWithPageFilter } from './banner-with-page-filter/BannerWithPageFilter';

export interface BannerSliceProps {
  slice: BannerVariations;
}

export const BannerSlice: FC<BannerSliceProps> = (props) => {
  const { slice } = props;

  switch (slice.variation) {
    case 'bannerCardSlice':
      return <BannerCard slice={slice as BannerCardVariation} />;
    case 'bannerWithCountrySearch':
      return (
        <BannerWithCountrySearch
          slice={slice as BannerWithCountrySearchVariation}
        />
      );
    case 'bannerCardWithImages':
      return (
        <BannerCardWithImages slice={slice as BannerCardWithImagesVariation} />
      );
    case 'bannerWithBorderedImage':
      return (
        <BannerWithBorderedImage
          slice={slice as BannerWithBorderedImageVariation}
        />
      );
    case 'bannerWithColumns':
      return <BannerWithColumns slice={slice as BannerWithColumnsVariation} />;
    case 'bannerWithVideo':
      return <BannerWithVideo slice={slice as BannerWithVideoVariation} />;
    case 'default-slice':
      return <DefaultBanner slice={slice as DefaultBannerVariation} />;
    case 'bannerPageFilter':
      return (
        <BannerWithPageFilter slice={slice as BannerWithPageFilterVariation} />
      );
    default:
      return null;
  }
};
