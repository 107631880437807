import { FC, useState } from 'react';

import {
  Autocomplete,
  Box,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { BannerWithPageFilterVariation } from '../types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageFilterSchema } from './schema';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { Button } from '@ui/buttons/Button';
import { SxStyles } from '@lib/theme/sxTheme';

interface PageFilter {
  url: string;
}

interface Props {
  slice: BannerWithPageFilterVariation;
}

export const BannerWithPageFilter: FC<Props> = ({ slice }) => {
  const {
    title,
    description,
    subtitle,
    image,
    filterButtonText,
    filterLabel,
    backgroundColor,
    paddingTop,
    paddingBottom,
    buttonBackgroundColor,
    buttonTextColor,
    buttonVariant = 'contained',
    width,
    pages,
  } = slice.primary;
  const router = useRouter();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = getStyles(isMobile);
  const [selectedPage, setSelectedPage] = useState<string | null>(null);

  const formMethods = useForm<PageFilter>({
    resolver: yupResolver(PageFilterSchema),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const filterOptions =
    pages
      ?.filter((page) => page?.name && page?.url)
      ?.map((page) => ({
        label: page?.name ?? '',
        id: page?.url?.url ?? '',
      })) ?? [];

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Stack
        spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}
        sx={styles.getValue('wrapper')}
      >
        {hasRichTextContent(title) && <CustomRichText render={title} />}
        {hasRichTextContent(description) && (
          <CustomRichText render={description} />
        )}
        {image?.url && (
          <DynamicImage
            containerProps={{ sx: styles.getValue('image') }}
            src={image.url}
            alt={image?.alt ?? ''}
            fill
            objectFit={'contain'}
          />
        )}
        {hasRichTextContent(subtitle) && <CustomRichText render={subtitle} />}
        <Box sx={styles.getValue('filterWrapper')}>
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(
                  _,
                  value: string | { label: string; id: string } | null,
                ) => {
                  if (typeof value === 'string') {
                    field.onChange(value);
                    setSelectedPage(null);
                  } else {
                    field.onChange(value?.label ?? '');
                    setSelectedPage(value?.id ?? null);
                  }
                }}
                options={filterOptions}
                id="offers-filter"
                sx={styles.getValue('filterInput')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={filterLabel ? filterLabel : null}
                    error={!!errors.url}
                    helperText={
                      errors?.url ? t(errors.url.message ?? '') : null
                    }
                  />
                )}
                value={
                  filterOptions.find(
                    (option) => option.label === field.value,
                  ) || null
                }
              />
            )}
          />
          <Box sx={styles.getValue('filterButtonWrapper')}>
            <Button
              onClick={handleSubmit(onFilterSubmit)}
              variant={buttonVariant ?? undefined}
              color={buttonBackgroundColor ?? undefined}
              sx={{
                color: buttonTextColor,
                fontSize: 18,
              }}
            >
              {filterButtonText ? filterButtonText : t('common:submit')}
            </Button>
          </Box>
        </Box>
      </Stack>
    </SliceLayout>
  );

  function onFilterSubmit() {
    if (!selectedPage) return;

    try {
      const url = new URL(selectedPage);
      window.location.href = url.href;
    } catch (e) {
      router.push(selectedPage);
    }
  }
};

const getStyles = (isMobile: boolean) => {
  return new SxStyles({
    wrapper: {
      textAlign: 'center',
      minHeight: 550,
    },
    image: {
      width: '100%',
      height: isMobile ? 200 : 500,
    },
    filterWrapper: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    filterInput: {
      width: 300,
    },
    filterButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  });
};
