import { FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';

import { DefaultBannerPrimary } from '../../types';
import { BannerFooter } from './BannerFooter';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  footerTitle: DefaultBannerPrimary['footerTitle'];
  footerSubtitle: DefaultBannerPrimary['footerSubtitle'];
  footerTextPosition: DefaultBannerPrimary['footerTextPosition'];
  footerBackgroundColor: DefaultBannerPrimary['footerBackgroundColor'];
  footerPaddingTop: DefaultBannerPrimary['footerPaddingTop'];
  footerPaddingBottom: DefaultBannerPrimary['footerPaddingBottom'];
  footerWidth: DefaultBannerPrimary['footerWidth'];
}

export const BannerFooterContainer: FC<Props> = (props) => {
  const {
    footerTitle,
    footerSubtitle,
    footerTextPosition,
    footerBackgroundColor,
    footerPaddingTop,
    footerPaddingBottom,
    footerWidth,
  } = props;
  if (!hasRichTextContent(footerTitle) && !hasRichTextContent(footerSubtitle)) {
    return null;
  }

  return (
    <SliceLayout
      backgroundColor={footerBackgroundColor}
      paddingTop={footerPaddingTop}
      paddingBottom={footerPaddingBottom}
      width={footerWidth}
    >
      <BannerFooter
        footerTitle={footerTitle}
        footerSubtitle={footerSubtitle}
        footerTextPosition={footerTextPosition}
      />
    </SliceLayout>
  );
};
