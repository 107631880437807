import { FC } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/system';
import { DynamicImage } from '@ui/gallery/DynamicImage';

import { DefaultBannerPrimary } from '../../types';

interface Props {
  image: DefaultBannerPrimary['image'];
  imageFit: DefaultBannerPrimary['imageFit'];
}

export const BannerBackground: FC<Props> = (props) => {
  const { image, imageFit } = props;

  if (!image?.url) {
    return null;
  }

  return (
    <Box
      flexShrink={0}
      zIndex={0}
      position="relative"
      sx={styles.getValue('fullWidthImage')}
    >
      <DynamicImage
        containerProps={{ sx: styles.getValue('image') }}
        src={image.url}
        alt={image?.alt ?? ''}
        fill
        objectFit={imageFit ?? 'contain'}
      />
    </Box>
  );
};

const styles = new SxStyles({
  image: {
    width: '100%',
    height: '100%',
  },
  fullWidthImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%,-50%)',
    display: 'flex',
    alignItems: 'center',
  },
});
