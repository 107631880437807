import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Paper, Stack, Box } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { BannerCardWithImagesVariation } from '../types';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';
import { BackgroundColor, ItemPosition } from '@lib/slice-machine/types';

interface Props {
  slice: BannerCardWithImagesVariation;
}

export const BannerCardPanel: React.FC<Props> = ({ slice }) => {
  const {
    panelContent,
    panelBackgroundColor,
    ctaTextLink,
    ctaBackgroundColor,
    ctaTextColor,
    buttonVariant,
    panelAlignment,
    showTitleInsidePanel,
    title,
  } = slice.primary;

  const dynamicStyles = styles({ panelAlignment, panelBackgroundColor });

  return (
    <Paper sx={dynamicStyles.getValue('card')} elevation={10}>
      <Stack spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}>
        {showTitleInsidePanel && hasRichTextContent(title) && (
          <Box sx={dynamicStyles.getValue('title')}>
            <CustomRichText render={title} />
          </Box>
        )}
        {hasRichTextContent(panelContent) && (
          <CustomRichText render={panelContent} />
        )}
        {hasRichTextContent(ctaTextLink) && (
          <CustomRichText
            render={ctaTextLink}
            linkBtnProps={{
              linkAsButton: true,
              variant: buttonVariant ?? undefined,
              color: ctaBackgroundColor ?? undefined,
              textProps: {
                color: ctaTextColor ?? undefined,
                fontSize: 18,
              },
            }}
          />
        )}
      </Stack>
    </Paper>
  );
};

interface StyleProps {
  panelBackgroundColor: BackgroundColor | null;
  panelAlignment: ItemPosition | null;
}

const styles = ({ panelAlignment, panelBackgroundColor }: StyleProps) => {
  return new SxStyles({
    card: {
      position: 'relative',
      p: (theme) =>
        theme.spacing(
          DEFAULT_SPACING_BETWEEN_ELEMENTS,
          2 * DEFAULT_SPACING_BETWEEN_ELEMENTS,
        ),
      backgroundColor: panelBackgroundColor,
      borderRadius: 2,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: 'light.main',
      zIndex: 1,
      textAlign: panelAlignment ?? 'center',
    },
    title: {
      position: 'relative',
      mt: (theme) =>
        `${theme.spacing(-1 * DEFAULT_SPACING_BETWEEN_ELEMENTS)} !important`,
      maxWidth: { xs: '90%', sm: '70%', md: '60%' },
      backgroundColor: 'light.main',
      borderRadius: 2,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      alignSelf: 'center',
      padding: (theme) => theme.spacing(1, 3),
    },
  });
};
