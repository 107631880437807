import { FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Stack } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { DefaultBannerPrimary } from '../../types';

interface Props {
  footerTitle: DefaultBannerPrimary['footerTitle'];
  footerSubtitle: DefaultBannerPrimary['footerSubtitle'];
  footerTextPosition: DefaultBannerPrimary['footerTextPosition'];
}

export const BannerFooter: FC<Props> = (props) => {
  const { footerTitle, footerSubtitle, footerTextPosition } = props;
  const dynamicStyles = styles({
    footerTextPosition,
  });

  return (
    <Stack sx={dynamicStyles.getValue('container')}>
      {hasRichTextContent(footerTitle) && (
        <CustomRichText render={footerTitle} />
      )}
      {hasRichTextContent(footerSubtitle) && (
        <CustomRichText render={footerSubtitle} />
      )}
    </Stack>
  );
};

interface StyleProps {
  footerTextPosition: DefaultBannerPrimary['footerTextPosition'];
}

const styles = ({ footerTextPosition }: StyleProps) =>
  new SxStyles({
    container: {
      textAlign: footerTextPosition,
    },
  });
