import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { SxStyles } from '@lib/theme/sxTheme';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { GenericPlaceholder } from './GenericPlaceholder';

interface Props {
  title?: string;
}

export const ErrorPlaceholder: FC<Props> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <GenericPlaceholder
      icon={<ErrorOutlineOutlinedIcon sx={styles.getValue('icon')} />}
      title={title ?? t('common:errors.unexpected')}
    />
  );
};

const styles = new SxStyles({
  icon: {
    fontSize: 50,
  },
});
