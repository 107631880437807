import { FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { Stack } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { DefaultBannerPrimary } from '../../types';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '@ui/slice-machine/slices/constants';

interface Props {
  description: DefaultBannerPrimary['description'];
  buttonTextLink: DefaultBannerPrimary['buttonTextLink'];
  buttonBackgroundColor: DefaultBannerPrimary['buttonBackgroundColor'];
  buttonTextColor: DefaultBannerPrimary['buttonTextColor'];
  buttonVariant: DefaultBannerPrimary['buttonVariant'];
  descriptionPosition: DefaultBannerPrimary['descriptionPosition'];
}

export const BannerDescription: FC<Props> = ({
  description,
  descriptionPosition,
  buttonTextLink,
  buttonBackgroundColor,
  buttonTextColor,
  buttonVariant,
}) => {
  const hasDescription = hasRichTextContent(description);
  const hasButton = hasRichTextContent(buttonTextLink);

  if (!hasDescription && !hasButton) {
    return null;
  }

  return (
    <Stack
      justifyContent={descriptionPosition ?? undefined}
      textAlign={descriptionPosition ?? undefined}
      spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}
    >
      {hasDescription && <CustomRichText render={description} />}
      {hasButton && (
        <CustomRichText
          render={buttonTextLink}
          linkBtnProps={{
            linkAsButton: true,
            variant: buttonVariant ?? undefined,
            color: buttonBackgroundColor ?? 'primary',
            textProps: {
              color: buttonTextColor ?? 'light.main',
              fontSize: 18,
            },
          }}
        />
      )}
    </Stack>
  );
};
