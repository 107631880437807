import { FC } from 'react';
import { useRouter } from 'next/router';

import { isLinkDefined, linkResolver } from '@lib/slice-machine/linkResolver';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Stack } from '@mui/material';
import { NodeLink } from '@ui/buttons/NodeLink';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { DefaultBannerItem } from '../../../types';

interface Props {
  icon: DefaultBannerItem['icon'];
  text: DefaultBannerItem['text'];
  link: DefaultBannerItem['link'];
}

export const BannerIcon: FC<Props> = (props) => {
  const { icon, text, link } = props;
  const router = useRouter();
  const isDefined = isLinkDefined(link);
  const href = isDefined ? linkResolver(link) : undefined;

  return (
    <Stack width={180} sx={styles.getValue('container')} spacing={2}>
      {icon?.url && (
        <NodeLink href={href}>
          <DynamicImage
            src={icon.url}
            alt={icon?.alt ?? ''}
            width={160}
            height={160}
            objectFit="contain"
            onClick={goToUrl}
          />
        </NodeLink>
      )}
      {hasRichTextContent(text) && <CustomRichText render={text} />}
    </Stack>
  );

  function goToUrl(): void {
    if (href) {
      router.push(href);
    }
  }
};

const styles = new SxStyles({
  container: {
    textAlign: 'center',
    alignItems: 'center',
  },
});
