import { FC } from 'react';

import { Box } from '@mui/system';

import { DefaultBannerVariation } from '../types';
import { BannerContentContainer } from './banner-content-container/BannerContentContainer';
import { BannerFooterContainer } from './banner-footer-container/BannerFooterContainer';

interface Props {
  slice: DefaultBannerVariation;
}

export const DefaultBanner: FC<Props> = (props) => {
  const { slice } = props;
  const {
    dividerColor,
    showDivider,
    titlePosition,
    descriptionPosition,
    backgroundColor,
    paddingTop,
    paddingBottom,
    width,
    imageFit,
    buttonBackgroundColor,
    buttonTextColor,
    buttonVariant,
    footerBackgroundColor,
    footerTextPosition,
    title,
    subtitle,
    description,
    image,
    buttonTextLink,
    footerTitle,
    footerSubtitle,
    footerPaddingTop,
    footerPaddingBottom,
    footerWidth,
  } = slice.primary;

  return (
    <Box>
      <BannerContentContainer
        dividerColor={dividerColor}
        showDivider={showDivider}
        titlePosition={titlePosition}
        backgroundColor={backgroundColor}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        width={width}
        imageFit={imageFit}
        buttonBackgroundColor={buttonBackgroundColor}
        buttonTextColor={buttonTextColor}
        buttonVariant={buttonVariant}
        title={title}
        subtitle={subtitle}
        description={description}
        image={image}
        buttonTextLink={buttonTextLink}
        iconItems={slice.items}
        descriptionPosition={descriptionPosition}
      />
      <BannerFooterContainer
        footerTitle={footerTitle}
        footerSubtitle={footerSubtitle}
        footerTextPosition={footerTextPosition}
        footerBackgroundColor={footerBackgroundColor}
        footerPaddingTop={footerPaddingTop}
        footerPaddingBottom={footerPaddingBottom}
        footerWidth={footerWidth}
      />
    </Box>
  );
};
