import { Breakpoint } from '@mui/system';
import { BLOCK_WIDTH_OPTIONS, MODAL_WIDTH_OPTIONS } from './constants';
import { BlockWidth, ModalWidth } from './types';

export const mapBlockWidthToBreakpoint = (
  width?: BlockWidth | null,
): Breakpoint =>
  width && width in BLOCK_WIDTH_OPTIONS
    ? BLOCK_WIDTH_OPTIONS[width]
    : BLOCK_WIDTH_OPTIONS['default'];

export const mapModalWidthToBreakpoint = (
  width?: ModalWidth | null,
): Breakpoint =>
  width && width in MODAL_WIDTH_OPTIONS
    ? MODAL_WIDTH_OPTIONS[width]
    : MODAL_WIDTH_OPTIONS['default'];
