import { FC } from 'react';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Stack, useTheme } from '@mui/material';
import { BannerWithVideoVariation } from '../types';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { Theme } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';
import { ItemPosition } from '@lib/slice-machine/types';
import { mapBlockPositionValue } from '@lib/slice-machine/blockPosition';

interface Props {
  slice: BannerWithVideoVariation;
}

export const BannerWithVideo: FC<Props> = ({ slice }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    backgroundColor,
    paddingTop,
    paddingBottom,
    subtitle,
    title,
    videoUrl,
    width,
    position,
  } = slice.primary;

  const dynamicStyles = styles({
    position,
    theme,
  });

  const embedUrl =
    videoUrl && isValidYouTubeUrl(videoUrl)
      ? transformToEmbedUrl(videoUrl)
      : null;

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Stack
        sx={dynamicStyles.getValue('container')}
        spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}
      >
        {hasRichTextContent(title) && <CustomRichText render={title} />}
        {hasRichTextContent(subtitle) && <CustomRichText render={subtitle} />}
        {embedUrl ? (
          <Box sx={dynamicStyles.getValue('videoIframeWrapper')}>
            <iframe
              src={embedUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        ) : (
          <ErrorPlaceholder title={t('common:errors.videoUrlInvalid')} />
        )}
      </Stack>
    </SliceLayout>
  );
};

function isValidYouTubeUrl(url: string): boolean {
  const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  return regex.test(url);
}

function transformToEmbedUrl(url: string): string | null {
  const regex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  if (match && match[1]) {
    return `https://www.youtube.com/embed/${match[1]}`;
  }
  return null;
}

interface StyleProps {
  position: ItemPosition | null;
  theme: Theme;
}

const styles = ({ position, theme }: StyleProps) => {
  return new SxStyles({
    container: {
      alignItems: mapBlockPositionValue(position),
      textAlign: position ?? 'center',
    },
    videoIframeWrapper: {
      position: 'relative',
      width: '100%',
      maxWidth: 640,
      height: 360,
      [theme.breakpoints.down('md')]: {
        maxWidth: 480,
        height: 270,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 320,
        height: 180,
        mt: 4,
      },
      overflow: 'hidden',
      p: 1,
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 4,
        border: `6px solid ${theme.palette.primary.main}`,
      },
    },
  });
};
