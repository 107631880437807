import { FC, useState } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import {
  Autocomplete,
  Box,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { BannerWithCountrySearchVariation } from '../types';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Button } from '@ui/buttons/Button';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { APP_ROUTES } from '@lib/route/constants';
import { withQueryParams } from '@lib/route/queryParams';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CountryFilterSchema } from './schema';

interface CountryFilter {
  countryFilter: string;
}

interface Props {
  slice: BannerWithCountrySearchVariation;
}

export const BannerWithCountrySearch: FC<Props> = ({ slice }) => {
  const {
    title,
    description,
    subtitle,
    image,
    filterButtonText,
    filterLabel,
    backgroundColor,
    paddingTop,
    paddingBottom,
    buttonBackgroundColor,
    buttonTextColor,
    buttonVariant = 'contained',
    width,
  } = slice.primary;
  const router = useRouter();
  const { filters } = useTenantProps();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = getStyles(isMobile);
  const [selectedOfferCategoryId, setSelectedOfferCategoryId] = useState<
    string | null
  >(null);

  const formMethods = useForm<CountryFilter>({
    resolver: yupResolver(CountryFilterSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const filterOptions =
    filters?.countries
      ?.filter(
        (country) => country?.offerCategoryId && country?.offerCategoryName,
      )
      .map((country) => ({
        label: country?.offerCategoryName,
        id: country?.offerCategoryId,
      })) ?? [];

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Stack
        spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}
        sx={styles.getValue('wrapper')}
      >
        {hasRichTextContent(title) && <CustomRichText render={title} />}
        {hasRichTextContent(description) && (
          <CustomRichText render={description} />
        )}
        {image?.url && (
          <DynamicImage
            containerProps={{ sx: styles.getValue('image') }}
            src={image.url}
            alt={image?.alt ?? ''}
            fill
            objectFit={'contain'}
          />
        )}
        {hasRichTextContent(subtitle) && <CustomRichText render={subtitle} />}
        <Box sx={styles.getValue('filterWrapper')}>
          <Controller
            name="countryFilter"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(
                  _,
                  value: string | { label: string; id: string } | null,
                ) => {
                  if (typeof value === 'string') {
                    field.onChange(value);
                    setSelectedOfferCategoryId(null);
                  } else {
                    field.onChange(value?.label ?? '');
                    setSelectedOfferCategoryId(value?.id ?? null);
                  }
                }}
                options={filterOptions}
                id="offers-filter"
                sx={styles.getValue('filterInput')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={filterLabel ? filterLabel : null}
                    error={!!errors.countryFilter}
                    helperText={
                      errors.countryFilter
                        ? t(errors.countryFilter.message ?? '')
                        : null
                    }
                  />
                )}
              />
            )}
          />
          <Box sx={styles.getValue('filterButtonWrapper')}>
            <Button
              onClick={handleSubmit(onFilterSubmit)}
              variant={buttonVariant ?? undefined}
              color={buttonBackgroundColor ?? undefined}
              sx={{
                color: buttonTextColor,
                fontSize: 18,
              }}
            >
              {filterButtonText ? filterButtonText : t('common:submit')}
            </Button>
          </Box>
        </Box>
      </Stack>
    </SliceLayout>
  );

  function onFilterSubmit() {
    if (!selectedOfferCategoryId) return;
    const url = withQueryParams(APP_ROUTES.PLANS.path, {
      offerCategoryId: selectedOfferCategoryId,
    });
    router.push(url);
  }
};

const getStyles = (isMobile: boolean) => {
  return new SxStyles({
    wrapper: {
      textAlign: 'center',
      minHeight: 550,
    },
    image: {
      width: '100%',
      height: isMobile ? 200 : 500,
    },
    filterWrapper: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    filterInput: {
      width: 300,
    },
    filterButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  });
};
