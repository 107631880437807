import { ItemPosition } from './types';

export const mapBlockPositionValue = (
  position: ItemPosition | null,
): string => {
  const positionValues: Record<ItemPosition, string> = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  return positionValues[position ?? 'center'];
};
