import { FC } from 'react';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Stack } from '@mui/material';
import { BannerWithBorderedImageVariation } from '../types';
import { useTheme } from '@mui/material/styles';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { TextColor } from '@lib/slice-machine/types';
import { Theme } from '@mui/system';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  slice: BannerWithBorderedImageVariation;
}

export const BannerWithBorderedImage: FC<Props> = ({ slice }) => {
  const {
    backgroundColor,
    mainImage,
    overlayImage,
    caption,
    borderColor,
    paddingTop,
    paddingBottom,
    width,
  } = slice.primary;
  const theme = useTheme();
  const dynamicStyles = styles({
    borderColor,
    theme,
  });

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Stack sx={dynamicStyles.getValue('container')}>
        <Box sx={dynamicStyles.getValue('imagesContainer')}>
          {mainImage?.url && (
            <DynamicImage
              containerProps={{ sx: dynamicStyles.getValue('mainImage') }}
              src={mainImage.url}
              alt={mainImage?.alt ?? ''}
              width={mainImage.dimensions.width as number}
              height={mainImage.dimensions.height as number}
            />
          )}
          {overlayImage?.url && (
            <DynamicImage
              containerProps={{ sx: dynamicStyles.getValue('overlayImage') }}
              src={overlayImage.url}
              alt={overlayImage?.alt ?? ''}
              width={overlayImage.dimensions.width as number}
              height={overlayImage.dimensions.height as number}
            />
          )}
        </Box>
        {hasRichTextContent(caption) && (
          <CustomRichText
            containerSx={dynamicStyles.getValue('caption')}
            render={caption}
          />
        )}
      </Stack>
    </SliceLayout>
  );
};

interface StyleProps {
  borderColor: TextColor | null;
  theme: Theme;
}

const styles = ({ borderColor, theme }: StyleProps) => {
  return new SxStyles({
    container: {
      alignItems: 'center',
    },
    imagesContainer: {
      position: 'relative',
      lineHeight: 0,
    },
    mainImage: {
      zIndex: 0,

      img: {
        borderWidth: 5,
        borderStyle: 'solid',
        borderColor: borderColor,
        borderRadius: 5,
        height: 'auto',
      },
    },
    overlayImage: {
      position: 'absolute',
      right: -20,
      bottom: -20,
      zIndex: 2,
      width: 'auto',
      height: 'auto',
      textAlign: 'right',

      img: {
        [theme.breakpoints.down('lg')]: {
          maxWidth: `90% !important`,
        },
        [theme.breakpoints.down(1000)]: {
          maxWidth: `80% !important`,
        },
        [theme.breakpoints.down(850)]: {
          maxWidth: `70% !important`,
        },
        [theme.breakpoints.down(700)]: {
          maxWidth: `60% !important`,
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: `50% !important`,
        },
        height: 'auto',
      },
    },
    caption: {
      textAlign: 'center',
      pt: 2,
    },
  });
};
