import { FC } from 'react';

import { Grid } from '@mui/material';

import { DefaultBannerVariation } from '../../../types';
import { BannerIcon } from './BannerIcon';

interface Props {
  items: DefaultBannerVariation['items'];
}

export const BannerIcons: FC<Props> = (props) => {
  const { items } = props;

  if (!items.length) {
    return null;
  }

  return (
    <Grid container spacing={4} my={2} justifyContent="center">
      {items.map((item, index) => (
        <Grid item key={index}>
          <BannerIcon icon={item.icon} text={item.text} link={item.link} />
        </Grid>
      ))}
    </Grid>
  );
};
