import { BlockPadding } from './types';
import { UseTenantComponentStylesReturn } from '@lib/tenants/useTenantComponentStyles';

export const mapBlockPaddingValue = (
  padding: BlockPadding | null,
  defaultSpacing: UseTenantComponentStylesReturn['spacing'],
): UseTenantComponentStylesReturn['spacing'] => {
  const paddingValues: Record<
    BlockPadding,
    number | UseTenantComponentStylesReturn['spacing']
  > = {
    none: 0,
    expanded: 15,
    default: defaultSpacing,
  };

  return paddingValues[padding ?? 'default'];
};
