import { FC } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import { Stack } from '@mui/material';
import { IntroBlock } from '@ui/slice-machine/slices/common/IntroBlock';

import { DefaultBannerItem, DefaultBannerPrimary } from '../../types';
import { BannerIcons } from './banner-icons/BannerIcons';
import { BannerBackground } from './BannerBackground';
import { BannerDescription } from './BannerDescription';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '@ui/slice-machine/slices/constants';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  dividerColor: DefaultBannerPrimary['dividerColor'];
  showDivider: DefaultBannerPrimary['showDivider'];
  titlePosition: DefaultBannerPrimary['titlePosition'];
  descriptionPosition: DefaultBannerPrimary['descriptionPosition'];
  backgroundColor: DefaultBannerPrimary['backgroundColor'];
  paddingTop: DefaultBannerPrimary['paddingTop'];
  paddingBottom: DefaultBannerPrimary['paddingBottom'];
  width: DefaultBannerPrimary['width'];
  imageFit: DefaultBannerPrimary['imageFit'];
  buttonBackgroundColor: DefaultBannerPrimary['buttonBackgroundColor'];
  buttonVariant: DefaultBannerPrimary['buttonVariant'];
  buttonTextColor: DefaultBannerPrimary['buttonTextColor'];
  title: DefaultBannerPrimary['title'];
  subtitle: DefaultBannerPrimary['subtitle'];
  description: DefaultBannerPrimary['description'];
  image: DefaultBannerPrimary['image'];
  buttonTextLink: DefaultBannerPrimary['buttonTextLink'];
  iconItems: DefaultBannerItem[];
}

export const BannerContentContainer: FC<Props> = ({
  dividerColor,
  showDivider,
  titlePosition,
  backgroundColor,
  paddingTop,
  paddingBottom,
  width,
  descriptionPosition,
  imageFit,
  buttonBackgroundColor,
  buttonTextColor,
  buttonVariant,
  title,
  subtitle,
  description,
  image,
  buttonTextLink,
  iconItems,
}) => {
  return (
    <SliceLayout
      backgroundColor={!!image?.url ? 'transparent' : backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
      contentProps={{ sx: styles.getValue('layout') }}
    >
      <BannerBackground image={image} imageFit={imageFit} />
      <Stack
        spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}
        sx={styles.getValue('content')}
      >
        <IntroBlock
          title={title}
          dividerColor={dividerColor}
          position={titlePosition}
          subtitle={subtitle}
          showDivider={showDivider}
        />
        <BannerDescription
          description={description}
          descriptionPosition={descriptionPosition}
          buttonTextLink={buttonTextLink}
          buttonBackgroundColor={buttonBackgroundColor}
          buttonTextColor={buttonTextColor}
          buttonVariant={buttonVariant}
        />
        <BannerIcons items={iconItems} />
      </Stack>
    </SliceLayout>
  );
};

const styles = new SxStyles({
  layout: {
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    position: 'relative',
  },
});
