import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Divider, Paper, Stack } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/system';
import { BannerCardWithImagesVariation } from '../types';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';

interface Props {
  slice: BannerCardWithImagesVariation;
}

export const BannerCardContent: React.FC<Props> = ({ slice }) => {
  const {
    leftCardTitle,
    leftCardDescription,
    rightCardTitle,
    rightCardDescription,
    rightCardPrice,
    rightCardCurrencyPeriod,
    ctaTextLink,
    ctaBackgroundColor,
    ctaTextColor,
    buttonVariant,
  } = slice.primary;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = getStyles(isMobile, theme);
  return (
    <Paper
      sx={styles.merge('card', isMobile && 'mobileCardWrapper')}
      elevation={10}
    >
      <Paper sx={styles.merge('leftCard', 'text')} elevation={2}>
        {hasRichTextContent(leftCardTitle) && (
          <CustomRichText render={leftCardTitle} />
        )}
        <Divider sx={styles.getValue('leftCardDivider')} variant="middle" />
        {hasRichTextContent(leftCardDescription) && (
          <CustomRichText render={leftCardDescription} />
        )}
      </Paper>
      <Box sx={styles.merge('rightCard', 'text')}>
        <Stack spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}>
          <Box>
            {hasRichTextContent(rightCardTitle) && (
              <CustomRichText render={rightCardTitle} />
            )}
            <Divider sx={styles.getValue('divider')} variant="middle" />
            <Box sx={styles.getValue('rightCardContentWrapper')}>
              {hasRichTextContent(rightCardDescription) && (
                <CustomRichText
                  containerSx={styles.getValue('rightCardDescription')}
                  render={rightCardDescription}
                />
              )}
              <Box sx={styles.getValue('rightCardPriceWrapper')}>
                {hasRichTextContent(rightCardPrice) && (
                  <CustomRichText
                    containerSx={styles.getValue('rightCardPrice')}
                    render={rightCardPrice}
                  />
                )}
                {hasRichTextContent(rightCardCurrencyPeriod) && (
                  <Box sx={styles.getValue('currencyPeriodWrapper')}>
                    <CustomRichText
                      containerSx={styles.getValue('currencyPeriod')}
                      render={rightCardCurrencyPeriod}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {hasRichTextContent(ctaTextLink) && (
            <CustomRichText
              render={ctaTextLink}
              linkBtnProps={{
                linkAsButton: true,
                variant: buttonVariant ?? undefined,
                color: ctaBackgroundColor ?? undefined,
                textProps: {
                  color: ctaTextColor ?? undefined,
                  fontSize: 18,
                },
              }}
            />
          )}
        </Stack>
      </Box>
    </Paper>
  );
};

const getStyles = (isMobile: boolean, theme: Theme) => {
  return new SxStyles({
    mobileCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      position: 'relative',
      width: '100%',
      height: isMobile ? 'auto' : 400,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 2,
      zIndex: 1,
    },
    leftCardDivider: {
      borderColor: theme.palette.secondary.main,
      borderWidth: 1,
      my: 1,
    },
    divider: {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      my: 1,
    },
    leftCard: {
      height: 'auto',
      borderRadius: 2,
      backgroundColor: theme.palette.primary.dark,
      p: theme.spacing(DEFAULT_SPACING_BETWEEN_ELEMENTS, 1),
      pt: 7,
      width: '100%',

      ...(!isMobile && {
        position: 'absolute',
        top: 0,
        left: 0,
        pt: 12.5,
        width: '40%',
        height: '100%',
        maxWidth: '80%',
        display: 'flex',
        flexDirection: 'column',
      }),
    },
    rightCard: {
      py: DEFAULT_SPACING_BETWEEN_ELEMENTS,
      maxWidth: '80%',

      ...(!isMobile && {
        position: 'absolute',
        top: 0,
        right: '5%',
        pt: 12.5,
        width: '50%',
        height: 'auto',
        minHeight: '50%',
      }),

      '& em': {
        fontSize: '50%',
        fontStyle: 'normal',
      },
    },
    text: {
      textAlign: 'center',
      textWrap: 'wrap',
    },
    currencyPeriodWrapper: {
      position: 'absolute',
      bottom: isMobile ? 2 : 5,
      left: isMobile ? '58%' : '46%',
      transform: 'translateX(-50%)',
    },
    currencyPeriod: { fontSize: '1rem' },
    rightCardPrice: {
      '& *': {
        fontSize: isMobile ? '4rem' : '5.1rem',
        lineHeight: 'inherit',
      },
    },
    rightCardPriceWrapper: {
      ml: 1,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    rightCardDescription: {
      maxWidth: '60%',
      '& *': {
        fontSize: isMobile ? '1.5rem' : '2.2rem',
        lineHeight: isMobile ? 1.1 : 0.9,
        fontWeight: 600,
        textAlign: 'right',
      },
    },
    rightCardContentWrapper: {
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
    },
  });
};
